import { Page, Navbar, ListItem, List, NavRight, Button, Badge, NavLeft, ActionsGroup, Actions, ActionsLabel, ActionsButton } from "framework7-react";
import React from "react";
import { getCustomer, getShelfOrder, releaseItem, saveOrder } from "./repository";
import KeyCaptureInstance, { KeyCaptureEvents } from './key_capture';
import cloneDeep from 'lodash.clonedeep';
import { FlexColumn, FlexRow } from "./common";
import EanCoder from "./ean_coder";
import { live } from "./live.service";
import { session } from "./session";
import { NarrowItemView } from "./narrow_item";
import { orderManager } from "./order_manager";

interface Props {
  
}

interface State {
  order: any
  item: any
  changed: boolean
  showList: boolean
  syncKey: number
  showActions: boolean
}

export class NarrowDetail extends React.Component<Props, State> {
  actions?: Actions
  overamount?: Actions
  state = {
    order: { items: [] } as any,
    item: {} as any,
    changed: false,
    showList: true,
    syncKey: 0,
    showActions: false
  } as State

  componentDidMount() {
    this.fetch()
    KeyCaptureInstance.observe(this.onBarcode);
    this.refocus();
    KeyCaptureInstance.reset();
  }

  refocus() {
    const activeElement = (window.document.activeElement as any);
    if (activeElement && activeElement.blur) {
      activeElement.blur()
    }
  }

  componentWillUnmount() {
    KeyCaptureInstance.cancel(this.onBarcode)
  }

  notify(text: string, title = "Viivakoodi skannattu") {
    var notification = this.$f7?.notification.create({
      title,
      text,
      closeTimeout: 3000
    })
    notification?.open()
  }

  private onBarcode = (event?: string, data?: any) => {
    console.log('on barcode', event, data);

    if (event !== KeyCaptureEvents.BARCODE_SCANNED) {
      return
    }

    const barcode = data as string;
    const code = EanCoder.get(barcode);
    const item = this.state.order.items.find((item: any) => item.code === code);
    if (item) {
      this.notify(barcode)
      this.setState({ item, showList: false })
    } else {
      this.notify(`${barcode} Tuotetta ei löytynyt`)
    }
    this.refocus()
  }

  async fetch() {
    this.$f7?.progressbar.show();
    const order = await getShelfOrder(this.$f7route?.params.id ?? '') as any;
    // live.send('update', { document: (order as any)['a:Sales_order_number'], session: session.id  })
    const stored = localStorage.getItem('current-order');
    if (stored) {
      const existing = JSON.parse(stored);
      if (existing['a:Sales_order_number'][0] === order['a:Sales_order_number'][0]) {
        // order matches the stored one, offer resume
        this.setState({ order, showActions: true })
      } else {
        this.setState({ order });  
      }
    } else {
      this.setState({ order });
    }
    this.$f7?.progressbar.hide()
  }

  private finish = async () => {
    const newOrder = cloneDeep(this.state.order)
    const customerNum = (this.state.order as any)['a:Sales_order_delivery_customer_number'][0];
    const customer = await getCustomer(customerNum);
    const canDoPartial = customer['a:Customer_partial_delivery_bit'][0] === 'false';
    newOrder['a:Sales_order_state'][0] = 25;
    newOrder.items.forEach((item: any, index: number) => {
      newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Sales_orderrow_total_delivery'][0] = 0;
      newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Delivered'][0] = item.collected;
      newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:ToBeDelivered'][0] = item.collected;
      newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Row_color'][0] = '';
      const isPartial =  parseInt(newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Row_original_amount'][0]) !== item.collected;
      newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:JT'][0] = canDoPartial && isPartial;

      if (!canDoPartial) {
        if (item.collected === 0) {
          newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Row_original_amount'][0] = 0;
          newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:Row_amount'][0] = 0;
          newOrder['a:OrderRows'][0]['a:SalesOrderRow'][index]['a:ToBeDelivered'][0] = 0;
        }
      }
    });

    delete newOrder.items;
    await saveOrder(newOrder);
    await orderManager.addOrder(newOrder);
    this.notify('Tilaus toimitettu', 'Success');
    localStorage.removeItem('current-order');

    this.$f7router?.back();
  }

  private onCollect = async (item: any, value: number) => {
    console.log('on collect', item);
    const expected = this.state.item.rowAmount;

    if (value > expected) {
      this.overamount?.open(true)
    } 

    const existing = this.state.order.items.find((i: any) => i.code === item.code);
    const existingIdx = this.state.order.items.findIndex((i: any) => i.code === item.code);

    if (existing) {
      (existing as any).collected = value;
    }

    this.setState({ order: this.state.order, changed: true ,syncKey: new Date().getTime(), showList: true }, () => {
      localStorage.setItem('current-order', JSON.stringify(this.state.order));
      this.refocus();
      if (existing) {
        const el = document.getElementById(`item-${existingIdx}`);
        if (el) {
          document.getElementsByClassName('sidebar')[0].scroll(0, el.offsetTop);
        }
      }
    })
  }

  private getColor(item: any) {
    const rowAmount = parseInt(item.rowAmount);

    if (item.collected === 0) {
      return '#dedede'
    }

    if (item.collected < rowAmount) {
      return 'orange'
    }

    if (item.collected === rowAmount) {
      return 'green'
    }

    if (item.collected > rowAmount) {
      return 'red'
    }

    return 'gray';
  }

  private hasItem() {
    return this.state.item.code && this.state.item.code.length > 0
  }

  private setItem = (item: any) => {
    this.setState({ item, showList: false })
  }


  private reloadCodes = async () => {
    await EanCoder.fetch();
    this.$f7?.notification.create({
      title: "Tuotetietokanta",
      text: `Ladattiin ${EanCoder.codes.length} tuotteen tiedot `,
      closeTimeout: 3000
    }).open()
  }

  private resume = async () => {
    const stored = localStorage.getItem('current-order') as string;
    const existing = JSON.parse(stored);
    this.setState({ order: existing, showActions: false })
  }

  private renderItems() {
    return this.state.order.items.map((item: any, index: number) => <ListItem 
      id={`item-${index}`}
      key={`item-${index}`}
      className={item.code === this.state.item.code ? 'active' : ''}
      onClick={() => this.setItem(item)}
    >
      <Badge className={`sync-${this.state.syncKey}`} style={{ 
        backgroundColor: this.getColor(item), 
        color: this.getColor(item) === '#dedede' ? 'black' : 'white', 
        transform: 'scale(1.5)', 
        marginLeft: 12,
        fontWeight: 'bold',
        padding: 6
      }} slot="after">
        { parseInt(item.rowAmount).toFixed(0) || ''}
      </Badge>
      <span slot="title" style={{ fontWeight: 'bold', fontSize: 16 }}>{`${(item.description || '')} (${item.collected})`}</span>
      <div slot="subtitle" style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
        <div style={{ flex: 3 }}>
          <Badge style={{ fontWeight: 'bold', color: 'black', backgroundColor: '#f3f3f3', padding: 12, fontSize: 16 }}>
            { item.defaultShelf }
          </Badge>
          <Badge style={{ color: 'black', backgroundColor: '#fafafa', padding: 12, fontSize: 14, marginLeft: 12 }}>
            <strong>{ EanCoder.getFreeSaldo(item.code) ?? '' }</strong>
          </Badge>
        </div>
        <div style={{ flex: 1, alignItems: 'flex-end', textAlign: 'right' }}>
          { item.code }
        </div>
      </div>
    </ListItem>)
  }

  public render() {
    return <Page name="detail" onPageReinit={() => this.refocus()}>
      <Navbar>
        <NavLeft>
          <Button 
            color={ this.state.changed ? "orange" : "blue" }
            text={`Tilaus ${this.$f7route?.params.id}`}
            onClick={async () => { 
              if (!this.state.showList) {
                this.setState({ showList: true }, () => this.refocus())
              } else {
                if (this.state.changed) {
                  this.actions?.open(true)
                } else {
                  console.log('releasing3:', this.state.order.Sales_order_id, this.state);
                  await releaseItem(this.state.order['a:Sales_order_id']);
                  live.send('update', { document: null, session: session.id  })
                  this.$f7router?.back()
                }
              }
            }}
            iconF7="chevron_left_circle_fill" 
            style={{ fontSize: 16, marginRight: 12, fontWeight: 'bold' }}
          />
          <Actions ref={(actions) => {
            this.actions = actions as any
          }}>
            <ActionsGroup>
              <ActionsLabel>Keräily on vielä kesken, haluatko varmasti palata takaisin? Keräilyä ei tallenneta.</ActionsLabel>
              <ActionsButton onClick={async () => {
                console.log('releasing4:', this.state.order.Sales_order_id, this.state);
                await releaseItem(this.state.order['a:Sales_order_id']);
                live.send('update', { document: null, session: session.id  });;
                this.$f7router?.back() 
              }} color="red" bold>Kyllä</ActionsButton>
              <ActionsButton onClick={() => { 
                this.actions?.close();
              }}>En</ActionsButton>
            </ActionsGroup>
          </Actions>

          <Actions ref={(overamount) => {
            this.overamount = overamount as any
          }}>
            <ActionsGroup>
              <ActionsLabel>Huom: Kerätty määrä on suurempi kuin tilattu</ActionsLabel>
              <ActionsButton onClick={() => { this.overamount?.close() }}>OK</ActionsButton>
            </ActionsGroup>
          </Actions>
        </NavLeft>
        <NavRight>
          <Button 
            fill
            onClick={this.reloadCodes} 
            iconF7="arrow_clockwise" 
            style={{ fontSize: 16, marginRight: 12 }}
          />
          <Button 
            fill
            color="blue"
            text="Toimita" 
            onClick={this.finish} 
            iconF7="checkmark_circle_fill" 
            style={{ fontSize: 16, marginRight: 12 }}
          />
        </NavRight>
      </Navbar>
      <div style={{ height: 'calc(100vh-56px)', maxHeight: 'calc(100vh-64px)', overflowY: 'hidden' }}>
        <FlexRow style={{ height: '100%' }}>
          { this.state.showList && 
            <List mediaList style={{ flex: 1, overflowY: 'scroll', height: 'calc(100vh - 64px)' }} className="sidebar">
              { this.renderItems() }
            </List>
          }
          { !this.state.showList && <React.Fragment>
            <FlexColumn style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#eaeaea', flexDirection: 'column' }}>
              <NarrowItemView item={this.state.item} onCollect={this.onCollect} />
            </FlexColumn>
          </React.Fragment>}
        </FlexRow>
      </div>
      <Actions opened={this.state.showActions}>
        <ActionsGroup>
          <ActionsLabel>Paikallinen kopio tilauksesta löytyi, haluatko jatkaa keräilyä aiemman pohjalta?</ActionsLabel>
          <ActionsButton onClick={() => this.resume()} bold>Kyllä</ActionsButton>
          <ActionsButton onClick={() => this.setState({ showActions: false })}color="red">Ei</ActionsButton>
        </ActionsGroup>
      </Actions>
    </Page>
  }
}
