import Axios from "axios";

export type EanCodes = { [id:string]: string }

const Minutes = [5, 20, 35, 50];

export class EanCoder {
  codes = [] as string[][]
  reversed ={} as EanCodes
  handle: any;

  constructor() {
    this.handle = setInterval(() => {
      const now = new Date();
      if (Minutes.includes(now.getMinutes())) {
        console.log(now, 'reloading ean codes & stocks...');
        this.fetch()
      }
    }, 1000 * 60)
  }
  
  fetch = async () => {
    const response = await Axios.get('/api/products/codes');
    this.codes = response.data;
  }

  getFreeSaldo = (code: string) => {
    const item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item[4];
    }

    return null
  }

  getItem = (_code: string) => {
    let code = _code;

    // first attempt with full code present
    let item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item;
    }

    // second attempt with 0 prefix added to code
    code = `0${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item;
    }

    // third attempt with 00 prefix added to code
    code = `00${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item;
    }

    // last attempt with 000 prefix added to code
    code = `000${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item;
    }

    return null
  }

  get = (_code: string) => {
    const item = this.getItem(_code);
    if (item) {
      return item[0]
    } else {
      return null
    }
  }

  getById = (id: string) => this.codes.find(c => c[4] === id)

  getEan = (_code: string) => {
    let code = _code;
    // first attempt with full code present
    let item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item[1];
    }

    // second attempt with 0 prefix added to code
    code = `0${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item[1];
    }

    // third attempt with 00 prefix added to code
    code = `00${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item[1];
    }

    // last attempt with 000 prefix added to code
    code = `000${code}`;
    item = this.codes.find(c => c[0] === code || c[1] === code || c[2] === code || c[3] === code);
    if (item) {
      return item[1];
    }

    return null
  }
}

const instance = new EanCoder();
export default instance;